import {
  LogoutOutlined,
  RiseOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { useAuth } from "../contexts/firebaseContext";

const PUBLIC_PAGES = { login: "/login" };
const AUTH_PAGES = {
  dashboard: "/",
  input: "/:uuid/input",
  view: "/:uuid/view",
  calls: "/calls",
};
export const ALL_PAGES = { ...PUBLIC_PAGES, ...AUTH_PAGES };

export const MENUS = () => {
  const { authUser } = useAuth();

  const isGaidoOrTraqqie =
    authUser?.company?.domain === "gaido.nl" ||
    authUser?.company?.domain === "traqqie.com";

  return [
    {
      key: AUTH_PAGES.dashboard,
      label: "Dashboard",
      icon: <RiseOutlined />,
    },
    ...(isGaidoOrTraqqie
      ? [
          {
            key: AUTH_PAGES.calls,
            label: "Calls",
            icon: <CommentOutlined />,
          },
        ]
      : []),
    {
      key: "logout",
      label: "Logout",
      icon: <LogoutOutlined />,
    },
  ];
};
