import Cookies from "js-cookie";

export const encodeBase64 = (data) => {
  return Buffer.from(data).toString("base64");
};
export const decodeBase64 = (data) => {
  return Buffer.from(data, "base64").toString("latin1");
};

export const getLocalStorageKey = () => encodeBase64("userInformation");

export const saveToLocalStorage = (values) => {
  Cookies.set("refreshToken", values?.refreshToken, 60);

  localStorage.setItem(
    encodeBase64(getLocalStorageKey()),
    encodeBase64(JSON.stringify(values))
  );
};

export const getFromLocalStorage = () => {
  try {
    const rawData = localStorage.getItem(encodeBase64(getLocalStorageKey()));
    const decodedData = rawData ? decodeBase64(rawData) : "{}";
    return JSON.parse(decodedData);
  } catch (error) {}
};
