import { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import {
  FirebaseProvider,
  FirebaseContext,
} from "../components/contexts/firebaseContext"; // Ensure FirebaseContext is exported
import "../styles/globals.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { ALL_PAGES } from "../components/utils/const";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const { authUser, isLoading } = useContext(FirebaseContext); // Access auth state from context

  useEffect(() => {
    // Redirect to login if user is not authenticated, or to dashboard if authenticated and trying to access login
    console.log("authuser", authUser);
    console.log("isloading", isLoading);

    if (!authUser && !isLoading && authUser !== undefined) {
      router.replace(ALL_PAGES.login);
    } else if (authUser && router.pathname === ALL_PAGES.login) {
      router.replace(ALL_PAGES.dashboard);
    }
  }, [authUser, isLoading, router]);

  if (isLoading || authUser === undefined) {
    // Show a loading indicator while determining authentication status
    return <div>Loading...</div>;
  }

  // Render the application normally
  return <Component {...pageProps} />;
}

// Wrap the app with the FirebaseProvider to provide context
export default function AppWrapper(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <FirebaseProvider>
        <MyApp {...props} />
      </FirebaseProvider>
    </QueryClientProvider>
  );
}
